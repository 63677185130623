import React from 'react';

import ShowcasePage from '../../../components/ShowcasePage';

const Showcase = () => (
  <ShowcasePage
    totalItems={26}
    s3Path={`portfolio/pregnant/7/`}
  />
)

export default Showcase;
